import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./screens/Home";
import { APP_ROUTES } from "./constants/routes";
import { ComingSoon } from "./screens/ComingSoon";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={APP_ROUTES.ROOT} element={<Home />} />
        <Route exact path={APP_ROUTES.COMING_SOON} element={<ComingSoon />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
