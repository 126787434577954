import { styled } from "styled-components";

export const HeadLine = styled.h1`
  font-family: "MTNBrighterSansBold";
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.25px;

  @media (min-width: 768px) {
    font-size: 76px;
    letter-spacing: 0.05px;
  }
  @media (min-width: 1025px) {
    font-size: 90px;
    letter-spacing: 0.05px;
  }
`;

export const SupportingText = styled.h3`
  font-family: "MTNBrighterSansLight";
  color: #000000;
  font-style: normal;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.05px;
  margin-top: 24px;
  margin-bottom: 32px;
  max-width: 300px;
  text-align: center;

  @media (max-height: 760px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    font-weight: 300;
    font-size: 34px;
    line-height: 20px;
    margin-top: 31px;
    margin-bottom: 52px;
    max-width: fit-content;
  }

  @media (min-width: 1025px) {
    font-weight: 300;
    font-size: 50px;
    line-height: 20px;
    margin-top: 21px;
    margin-bottom: 46px;
  }
`;

export const CallToActionText = styled.p`
  font-family: "MTNBrighterSansLight";
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05px;

  @media (min-width: 768px) {
    font-weight: 300;
    font-size: 26px;
  }

  @media (min-width: 1025px) {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const Link = styled.a`
  color: #ffcb05;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05px;

  @media (min-width: 768px) {
    font-weight: 300;
    font-size: 26px;
    line-height: 48px;
  }

  @media (min-width: 1025px) {
    font-size: 36px;
    line-height: 48px;
  }
`;
export const ButtonLink = styled.a`
  background-color: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.05px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  height: 42px;
  width: 187px;
  border-radius: 40px;
  margin-top: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e4ac03;
  }

  @media (min-width: 768px) {
    margin-top: 24px;
    font-size: 24px;
    height: 64px;
    width: 346px;
  }

  @media (min-width: 1025px) {
  }
`;
