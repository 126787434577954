import React from "react";
import { StyledAppBar, StyledLogo } from "./AppBar.styles";
import LogoImage from "../../../assets/images/logo.png";

function AppBar() {
  return (
    <StyledAppBar>
      <StyledLogo src={LogoImage} />
    </StyledAppBar>
  );
}

export default AppBar;
