import { styled } from "styled-components";

export const StyledFooter = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05px;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #000000;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;
