import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { COUNTRIES } from "../../constants/countries";
import { APP_ROUTES } from "../../constants/routes";

const Home = () => {
  const navigate = useNavigate();

  const fetchIpInfo = () => {
    fetch("https://ipapi.co/json/")
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        if (res.country_calling_code) {
          if (COUNTRIES[res.country_calling_code]) {
            window.location.href = COUNTRIES[res.country_calling_code];
          } else {
            navigate(APP_ROUTES.COMING_SOON, { replace: true });
          }
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  useEffect(() => {
    fetchIpInfo();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <LoadingSpinner />
    </div>
  );
};

export default Home;

const LoadingSpinner = styled(motion.div)`
  min-width: 25px;
  min-height: 25px;
  border: 4px solid rgb(255, 203, 5);
  border-bottom-color: transparent;
  border-radius: 45px;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
