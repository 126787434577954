import React from "react";
import { StyledFooter } from "./Footer.styles";

function Footer() {
  return (
    <StyledFooter>
      <p>
        © 2023 MTN Group Management Services (Pty) Ltd. All Rights Reserved.
      </p>
    </StyledFooter>
  );
}

export default Footer;
