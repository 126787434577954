export const COUNTRIES = Object.freeze({
  "+260": "https://www.mtn.zm/play/", //Zambia
  "+245": "https://mtngbissau.com/play/", //Guinea Bissau
  "+234": "https://www.mtn.ng/play/", //Nigeria
  "+237": "https://mtn.cm/play/", //Cameroon
  "+231": "https://lonestarcell.com/digital/", //Liberia
  "+256": "https://www.mtn.co.ug/mtn-play/", //Uganda
  "+249": "https://www.mtn.sd/mtn/play/", //Sudan
  "+233": "https://app-prod.mtnplay.com", // Ghana
  "+27": "https://play.mtn.co.za/", //South Africa
});
