import { AppBar } from "../../shared/components/AppBar";
import { Body } from "../../shared/components/Body";
import { Footer } from "../../shared/components/Footer";
import {
  ButtonLink,
  CallToActionText,
  HeadLine,
  SupportingText,
} from "../../shared/components/Text";

const ComingSoon = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100vh",
      }}
    >
      <AppBar />
      <Body>
        <HeadLine>Coming Soon</HeadLine>
        <SupportingText>It’s going to be amazing!</SupportingText>
        <CallToActionText>Already signed up?</CallToActionText>
        <ButtonLink> Click here to sign in.</ButtonLink>
      </Body>
      <Footer />
    </div>
  );
};

export default ComingSoon;
