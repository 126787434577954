import { styled } from "styled-components";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  line-height: 40px;
  margin-left: 1%;
  margin-top: 16%;
  margin-bottom: auto;

  @media (min-width: 1280px) {
    line-height: normal;
    width: fit-content;
    margin-left: 8%;
    margin-top: 1%;
  }
  @media (min-width: 1440px) {
    margin-left: 18%;
    margin-top: 2%;
  }
  @media (min-width: 1728px) {
    margin-left: 14%;
    margin-top: 9%;
  }
  @media (min-width: 1920px) {
    margin-left: 27%;
    margin-top: 6%;
  }
  @media (min-width: 2048px) {
    margin-left: 23%;
    margin-top: 7%;
  }
`;

export default Body;
