import { styled } from "styled-components";

export const StyledAppBar = styled.div`
  height: 136px;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 48px;
  padding-bottom: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (max-width: 768px) {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.6) 66%
    );
    padding-left: 24px;
    padding-right: 24px;
    height: 96px;
  }
`;

export const StyledLogo = styled.img`
  height: 50px;
  width: 249.65px;
  left: 47.94px;

  @media (max-width: 768px) {
    height: 35.29px;
    width: 176.22px;
    left: 25px;
    top: 56px;
  }
`;
